import { testMyRentalsShallowMount } from "@/tests/unit/setup";
import OwnerProperties from "@/components/OwnerProperties.vue";

jest.mock("@/services/MyRentalsConfig");

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("OwnerProperties", () => {
  testMyRentalsShallowMount(OwnerProperties);
});
